
import TheLiveChat from '@/modules/membership/components/TheLiveChat.vue'
import TheLiveNotice from '@/modules/membership/components/TheLiveNotice.vue'
import TheLiveVideo from '@/modules/membership/components/TheLiveVideo.vue'
import useLiveRoom from '@/modules/membership/live'
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'FullscreenLive',
  components: { TheLiveChat, TheLiveNotice, TheLiveVideo },
  setup () {
    const query = useRoute().query

    const {
      liveAddress,
      liveReady,
      chatConfig,
    } = useLiveRoom(query.liveId as string, query.chatId as string)

    return {
      liveAddress,
      liveReady,
      chatConfig,
      attrs: query,
    }
  },
})
